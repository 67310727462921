
body {
  background-color: transparent !important;
}
video {
  background-color: black;
}

div.container-fluid {
  background-color: rgba(0, 0, 0, 0.1);
}
.nav {
  color: white;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  width: 100%;
  padding: 10px;
  position: fixed;
  z-index: 3;
}

.nav h1 {
  margin-left: 100px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

video {
  position: absolute;
  top: 0;
  left: 0;
}
.card {
  min-height: 400px;
}
.card-title.h5 {
  color: white;
  font-size: 20px;
}
.card-text {
  color: white;
  font-size:14px;
}

div.card {
  background-color: rgba(227, 227, 227, 0.2);
}
div.card a {
  color: white;
  background-color: rgba(22, 17, 12, 0.721);
  padding: 3px 10px 8px 10px;
  margin: 10px;
  border-radius: 10px;
  font-size: 20px;;
  border-style: solid;
  border-color: white;
  border-width: thin;
  text-decoration: none;
  position: absolute;
  bottom: 0;
  right: 0;
}
button.btn.btn-primary {
  background-color: black;
  border-style: none;
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
}

.bottom img {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  z-index: -1;
}

.top img {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  z-index: -1;
}
.icons {
  margin-left: 97%;  
}
.bottom-icons {
  position: fixed;
  left: 40px;
  bottom: 50px;
  padding: 15px;
  border-radius: 50px;
  border-style: solid;

}
.projects {
  color: white;
  margin-top: 100px !important;
  background-color: rgba(0, 0, 0, 0.86);
  border-style: solid;
  border-color: rgba(227, 227, 227, 0.2);
  min-height: 90vh;
  margin: 0 auto;
}
#center-cards {
  margin:10px;
  display: flex;
  justify-content: center;
}
.projects h1 {
  padding: 5px;
}
.section-title { 
  background-color: rgba(82, 83, 91, 0.5);
}

#thumbnail {
  width: 150px;
  height: auto;
  margin: 20px;
}

#bio h5{
  padding: 50px;
  font-size: 20px;
}
#linkedin {
  display: flex;
  justify-content: right;
  padding: 25px;
}

.nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;

}

.nav li {
  float: left;
}

.nav li a {
  font-size: 15pt;
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}


#name a {
  color: rgb(16, 122, 220);
  border-style: solid;
  border-radius: 25px;
  margin-left: 20px;
  margin-right: 20px;
}
.about {
  color: white;
  margin-top: 100px !important;
  background-color: rgba(0, 0, 0, 0.86);
  border-style: solid;
  border-color: rgba(227, 227, 227, 0.2);
  margin: 0 auto;
}

.navbar,
.navbar-default {
  background-color: rgba(0, 0, 0, 0.86);
  color: white !important;
  border-color: rgb(16, 122, 220) !important;
  border-style: solid !important;
  border-top-width: 2px !important;
}

.navbar-brand {
  color: rgb(16, 122, 220) !important;
  border-style: solid !important;
  border-radius: 25px !important;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 5px;
}
.nav-link {
  color: white !important;
}
.navbar-toggler {
  color: white !important;
  background-color:rgba(16, 122, 220, .5) !important;
}